<template>
  <div class="CommerceOrderDetails">
    <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
      <!--begin::Entry-->
      <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container p-0">
          <!--begin::Page Layout-->
          <div class="d-flex flex-row">
            <!--begin::Layout-->
            <div class="flex-row-fluid">
              <!--begin::Card-->
              <div class="card card-custom gutter-b border">
                <div class="card-body p-0">
                  <!-- begin: Invoice-->
                  <!-- begin: Invoice header-->
                  <div class="row justify-content-center py-8 px-8 py-md-27 px-md-0">
                    <div class="col-md-10">
                      <div class="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
                        <h1 class="display-4 font-weight-boldest mb-10">{{ $t("GENERAL.MY_ORDER_DETAIL") }}</h1>
                        <div class="d-flex flex-column align-items-md-end px-0">
                          <!--begin::Logo-->
                          <a href="#" class="mb-5">
                            <img src="/media/logos/life-academy/200.png" alt="" class="max-w-70px" />
                          </a>
                          <!--end::Logo-->
                          <span class="d-flex flex-column align-items-md-end opacity-70">
                            <span>
                              <a href="https://lifeacademy.pro">lifeacademy.pro</a>
                            </span>
                            <span>1989 - {{ currentYear }}©</span>
                          </span>
                        </div>
                      </div>
                      <div class="border-bottom w-100"></div>
                      <div class="d-flex justify-content-between pt-6">
                        <div class="d-flex flex-column flex-root">
                          <span class="font-weight-bolder mb-2">{{ $t("GENERAL.DATE") }}</span>
                          <span class="opacity-70">Jan 07, 2020</span>
                        </div>
                        <div class="d-flex flex-column flex-root">
                          <span class="font-weight-bolder mb-2">ORDER NO.</span>
                          <span class="opacity-70">64616-103</span>
                        </div>
                        <div class="d-flex flex-column flex-root">
                          <span class="font-weight-bolder mb-2">{{ $t("GENERAL.MY_DATA") }}</span>
                          <span class="opacity-70"
                            >{{ orderUser.name }} {{ orderUser.surname }} <br />{{ orderUser.email }} <br />
                            {{ orderUser.white_label_name }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- end: Invoice header-->
                  <!-- begin: Invoice body-->
                  <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                    <div class="col-md-10">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th class="pl-0 font-weight-bold text-muted text-uppercase">{{ $tc("GENERAL.CONTENT", 2) }}</th>
                              <th class="text-right font-weight-bold text-muted text-uppercase">{{ $tc("GENERAL.QUANTITY", 2) }}</th>
                              <th class="text-right font-weight-bold text-muted text-uppercase">{{ $tc("GENERAL.PRICE", 2) }}</th>
                              <th class="text-right pr-0 font-weight-bold text-muted text-uppercase">{{ $tc("GENERAL.TOTAL", 2) }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="font-weight-boldest" v-for="(bundlePurchase, index) in bundlePurchases" :key="index">
                              <td class="pl-0 pt-7 d-flex align-items-center" :class="{ 'border-0': index === 0 }">
                                <!--begin::Symbol-->
                                <div class="symbol symbol-40 flex-shrink-0 mr-4 bg-light">
                                  <div
                                    class="symbol-label"
                                    :style="{ backgroundImage: `url(${$laUtils.contentImagePath(bundlePurchase.bundle.photo_url)})` }"
                                  ></div>
                                </div>
                                <!--end::Symbol-->
                                {{ bundlePurchase.bundle.texts.name }}
                              </td>
                              <td class="text-right pt-7 align-middle">{{ bundlePurchase.quantity }}</td>
                              <td class="text-right pt-7 align-middle">R$ {{ bundlePurchase.net_price }}</td>
                              <td class="text-primary pr-0 pt-7 text-right align-middle">R$ {{ bundlePurchase.total }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- end: Invoice body-->
                  <!-- begin: Invoice footer-->
                  <div class="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0 mx-0">
                    <div class="col-md-10">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th class="font-weight-bold text-muted text-uppercase">{{ $t("GENERAL.PAYMENT_TYPE") }}</th>
                              <th class="font-weight-bold text-muted text-uppercase">{{ $t("GENERAL.STATUS") }}</th>
                              <th class="font-weight-bold text-muted text-uppercase">{{ $t("GENERAL.DATE") }}</th>
                              <th class="font-weight-bold text-muted text-uppercase text-right">{{ $t("GENERAL.TOTAL") }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="font-weight-bolder">
                              <td>{{ purchaseOrder.purchase_origin_type }}</td>
                              <td>
                                <span class="label label-lg label-inline label-primary">{{ pubchaseOrigin.status }}</span>
                              </td>
                              <td>{{ pubchaseOrigin.created_at }}</td>
                              <td class="text-primary font-size-h3 font-weight-boldest text-right">R$ {{ totalPaid }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- end: Invoice footer-->
                  <!-- begin: Invoice action-->
                  <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                    <div class="col-md-10">
                      <div class="d-flex justify-content-between">
                        <button type="button" class="btn btn-light-primary font-weight-bold" onclick="window.print();">Download Order Details</button>
                        <button type="button" class="btn btn-primary font-weight-bold" onclick="window.print();">Print Order Details</button>
                      </div>
                    </div>
                  </div>
                  <!-- end: Invoice action-->
                  <!-- end: Invoice-->
                </div>
              </div>
              <!--end::Card-->
            </div>
            <!--end::Layout-->
          </div>
          <!--end::Page Layout-->
        </div>
        <!--end::Container-->
      </div>
      <!--end::Entry-->
    </div>
  </div>
</template>

<script>
import { GET_MY_ORDER } from "@/core/services/store/my-orders.module";

export default {
  name: "commerce-order-details",
  data() {
    return {
      order: null,
    };
  },
  computed: {
    currentYear() {
      let d = new Date();

      return d.getFullYear();
    },
    orderUser: {
      get() {
        return this.order?.user ?? {};
      },
    },
    purchaseOrder: {
      get() {
        return this.order ?? {};
      },
    },
    pubchaseOrigin: {
      get() {
        return this.purchaseOrder.purchase_origin ?? {};
      },
    },
    bundlePurchases: {
      get() {
        return this.order?.bundle_purchases ?? [];
      },
    },
    totalPaid: {
      get() {
        return this.bundlePurchases.reduce((accumulator, currentValue) => accumulator + (currentValue?.total ?? 0), 0);
      },
    },
  },
  mounted() {
    let self = this;
    this.$store.dispatch("myOrders/" + GET_MY_ORDER, this.$route.params.id).then((response) => (self.order = response));
  },
};
</script>
